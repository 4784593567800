//(function() {

//var Piece = function(board, color, type, pos) {
 function Piece(board, color, type, pos) {    
  var self = this;
  var ncub = -1;
//
  self.pos = ko.observable(pos);
  self.type = ko.observable(type);
  self.color = ko.observable(color);
  //var ncub = window.puzzle.board.pgn().slice(0, window.puzzle.board.pgn().indexOf(' ')); 
  if (self.type() == 'z') {
    ncub = puzzleEditorViewModel.board.fen().slice(0, puzzleEditorViewModel.board.fen().indexOf(' ')).search(/[xyprnbqk]/);
/*    console.log(puzzleEditorViewModel.board.fen().slice(0, puzzleEditorViewModel.board.fen().indexOf(' ')));
    console.log(puzzleEditorViewModel.board.fen().slice(0, puzzleEditorViewModel.board.fen().indexOf(' ')).search(/[xyprnbqk]/));
    console.log(' ----- ' + ncub);  
    */
  }
  self.cssClasses = ko.computed(function(){
    var classes = {};
    classes[self.color() + "-" + self.type()] = true;
    classes["gj"] = self.type() == 'z' && ncub !== -1;
    classes["ui-draggable-disabled"] = self.type() === 'u' || self.type() === 'U';
    classes["n-a"] = board.pieces().filter(piece => piece.type() !== 'z' && piece.color() === 'b').length !== 0 && self.type() === 'z';
    if (window.puzzle.category === 0){
      classes['attack'] = self.type() == 'k' && board.chess.attack(board.turn()) && board.turn() == self.color();
    }
    return classes;
  });
  self.board = ko.observable(board);
  self.moveable = ko.computed(function(){
    var board = self.board();
    if(board.moveMode() === 'free') {
      return true
    }
    else if(board.moveMode() === 'rules') {
      if (window.puzzle.category === 0){
        return !board.disabled() && window.puzzle.player_color === self.color() && board.turn() === self.color();
        //return !board.disabled() && board.turn() === self.color();
      }
      else{
      //console.log(`nnnnnn ` + self.type() + "  -  " + self.pos() + "  -  " + self.color() + "  -  " + board.control(self.color()) + "  -  " + board.turn());
      return !board.disabled() && board.control(self.color()) && board.turn() === self.color();
      }
    }
    return false;
  });

  self.activate = function(){
    self.board().activePiece(self);
  }
};
//window.Piece = Piece;
//}).call(this);


//(function() {

//var Cell = function(board, pos) {
function Cell(board, pos) {
                                        //       console.log('00000000000000000000000--------  ' + board.pieces().filter(piece => piece.type() === 'z').length);
  var self = this
  self.pos = ko.observable(pos);
  self.color = ko.computed(function(){
    var i, j;
    i = Utils.file(self.pos());
    j = Utils.rank(self.pos());
    return (i + j) % 2 ? 'w' : 'b';
  });
  self.board = ko.observable(board);
  self.acceptPiece = ko.computed(function(){
    var board = self.board(), activePiece = board.activePiece(), moveMode = board.moveMode();
    return (activePiece && 
      (moveMode === 'free' || (moveMode === 'rules' && board.activePieceDestinations().indexOf(self.pos()) !== -1))) //||
  })

  self.cssClasses = ko.computed(function(){
    
   var board = self.board(), activePiece = board.activePiece(), pos1;
   pos1 = activePiece ? activePiece.pos() : 0;
   if (self.pos() === 'e6') {
}
if (board.pieces().filter(piece => piece.type() === "z").length > 0) {
//////////console.log('board.pieces().filter(piece => piece.type() === "z").indexOf(self.pos()) --- ' + JSON.stringify(board.pieces().filter(p => p.type() === 'z' && p.pos() === self.pos()).length) + ' --- ' + self.pos())
} 
    var classes = {};
    classes[self.color()] = true;
    classes['active'] = self.board().moveMode() === 'rules' && self.acceptPiece() && 
                        board.activePieceDestinations().indexOf(self.pos()) !== -1 && 
                        board.palings().indexOf(self.pos()) === -1 //&& 
                        //(board.pieces().filter(p => p.type() === 'z' && p.pos() === self.pos()).length === 0 || 
                        //(board.pieces().filter(p => p.type() === 'z' && p.pos() === self.pos()).length > 0 && 
                        //board.pieces().filter(p => p.type() === 'z').length === board.pieces().filter(p => p.color() === 'b').length))
                        ;
    classes['m-active'] = (pos === pos1) ; //|| (board.movelast().indexOf(self.pos()) !== -1);
    classes['ui-droppable'] = board.palings().indexOf(self.pos()) === -1 && 
                        (board.pieces().filter(p => p.type() === 'z' && p.pos() === self.pos()).length === 0 || 
                        (board.pieces().filter(p => p.type() === 'z' && p.pos() === self.pos()).length > 0 && 
                        board.pieces().filter(p => p.type() === 'z').length === board.pieces().filter(p => p.color() === 'b').length));
    classes['cell-paling'] = board.palings().indexOf(self.pos()) !== -1 ;

    return classes;
  });

  self.drop = function(piece){
    self.board().drop(piece, self.pos());
    if(window.puzzle && window.puzzle.category === 0){
      console.log("drop");
    }
  }
};
//window.Cell = Cell;
//}).call(this);

(function() {

var ChessBoard = function(options) {
//function ChessBoard(options) {
                                                 console.log('var ChessBoard');
  var self = this;
  self.chess = new Chess();
  self.options = options;

  self.rules = options.rules || {}

  self.pieces = ko.observableArray();
  self.cells = [];
  self.movelast = ko.observableArray([]);
  self.flip = ko.observable(options.flip);
//---------------------------------------------------  
//  self.movess = ko.observableArray([]);
  self.pcturn = ko.observable(false);
  self.pturn = ko.observable(false);
  self.pmturn = ko.observable(false);
  self.attack = ko.observable(false);
  self.palings = ko.observableArray([]);
//--------------------------------------------------  
  self.cellSize = ko.observable(options.cellSize);
  self.turn = ko.observable(self.chess.turn());
  self.strict = ko.observable(true);
  self.canThrowOut = ko.observable(false);
  self.selectedAddingPiece = ko.observable(null);
  self.boxPieces = [
    {type: 'p', color: 'w'},
    {type: 'r', color: 'w'},
    {type: 'n', color: 'w'},
    {type: 'b', color: 'w'},
    {type: 'q', color: 'w'},
    {type: 'k', color: 'w'},
    {type: 'u', color: 'w'},

    {type: 'p', color: 'b'},
    {type: 'r', color: 'b'},
    {type: 'n', color: 'b'},
    {type: 'b', color: 'b'},
    {type: 'q', color: 'b'},
    {type: 'k', color: 'b'},
    {type: 'u', color: 'b'},
    {type: 'x', color: 'b'},
    {type: 'y', color: 'b'},
    {type: 'z', color: 'b'}
  ]
  self.pakPieces = [
    {type: 'p', color: 'w'},
    {type: 'p', color: 'b'},
    {type: 'r', color: 'w'},
    {type: 'r', color: 'b'},
    {type: 'n', color: 'w'},
    {type: 'n', color: 'b'},
    {type: 'b', color: 'w'},
    {type: 'b', color: 'b'},
    {type: 'q', color: 'w'},
    {type: 'q', color: 'b'},
    {type: 'k', color: 'w'},
    {type: 'k', color: 'b'},
    {type: 'u', color: 'w'},
    {type: 'u', color: 'b'},
    {type: 'x', color: 'b'},
    {type: 'y', color: 'b'},
    {type: 'z', color: 'b'}

  ]
  self.disabled = ko.observable(false);

  self.droppedOut = function(piece){
    if(self.canThrowOut()) {
      self.reset(piece.pos());
      return false; // do not revert
    }
    return true; // revert piece
  }

  self.playerSide = function(color) {
    return (color === 'w') ^ self.flip() ? 'bottom' : 'top'
  }

  self.activePiece = ko.observable(null);
  self.activePieceDestinations = ko.computed(function(){
    var moves, activePiece = self.activePiece(), activePos;
    
    var destinations = []
    if(!activePiece || self.moveMode() === 'free') {
      return destinations;
    }
    
    activePos = activePiece.pos();
    moves = self.chess.moves({verbose: true, mmm3: self.pturn()});
//     alert(moves.length);
    for(var i=0;i<moves.length;i++) {
      if(moves[i].from === activePos) {
        destinations.push(moves[i].to);
  //      console.log(moves[i]);
      }
    }
    return destinations;
  })


  self.ranks = [1,2,3,4,5,6,7,8]
  self.files = ["a", "b", "c", "d", "e", "f", "g", "h"]

  self.rankPos = function(j) {
    return ((self.flip() ? j : 7-j)*12.5).toString()+"%"
  }

  self.filePos = function(i) {
    return ((self.flip() ? 7-i : i)*12.5).toString()+"%"
  }

  self.moveMode = ko.observable("rules");


  self.controlWhite = ko.observable(false);
  self.controlBlack = ko.observable(false);
  self.control = function(color) {
    var obs;
    if(color === 'w') {
      obs = self.controlWhite
    } else if (color === 'b') {
      obs = self.controlBlack
    }

    if(obs) {
      if(arguments.length > 1) {
        obs(arguments[1]);
        return self;
      } else {
        return obs();
      }
    }
  }

  self.reset = function(removePos, addPiece, addPos){
    if(removePos) {
      self.chess.remove(removePos);
    }
    if(addPiece && addPos) {
      self.chess.put(addPiece, addPos);
    }
    self.updateBoard();
    if(self.onreset) {
      self.onreset();
    }
  }

  self.promotingMove = null;

  self.goblin2 = function (m5) {
    setTimeout(function(){
        self.makeMove23({from: m5.from, to: m5.to}, true);
      }, 390);
      self.goblin4();
      /*
     setTimeout(function(){ 
         if(!window.puzzle.solved && !window.puzzle.err){
          window.puzzle.mistake +=1;
            wsolve(window.puzzle);
            if(window.puzzle.locale === 'ru'){
              alert('Задача не решена!')
              }else if(window.puzzle.locale === 'uk'){
                alert('Задача не вирішена!')
                }else{
                 alert('The problem is not solved!')
                 };
          }else{                    
            if(window.puzzle.locale === 'ru'){
              alert('Не правильно!!!!!!')
              }else if(window.puzzle.locale === 'uk'){
                alert('Не вірно!!!!!!')
                }else {alert('Wrong!!!!!!!')
                };
            } 
            }, 450); 
setTimeout(function(){             
      puzzleEditorViewModel.displayMessage(false);
      solved(window.puzzle); 
      self.disabled(true); 
      }, 470);
      */
  }

  self.goblin4 = function () {
     setTimeout(function(){ 
         if(!window.puzzle.solved && !window.puzzle.err){
          window.puzzle.mistake +=1;
            wsolve(window.puzzle);
         /*   if(window.puzzle.locale === 'ru'){
              alert('Задача не решена!')
              }else if(window.puzzle.locale === 'uk'){
                alert('Задача не вирішена!')
                }else{
                 alert('The problem is not solved!')
                 };*/
          }else{  

    $("#taskModal1").modal('show');
if (sound) {document.getElementById("m010").play(); }         
    setTimeout(function() {
     $('#taskModal1 .close-btn').click();
     wpuzzle(window.puzzle.id, sign1, window.puzzle.ipuz);
     }, 2000); 

   /*                         
            if(window.puzzle.locale === 'ru'){
              alert('Не правильно!!!!!!')
              }else if(window.puzzle.locale === 'uk'){
                alert('Не вірно!!!!!!')
                }else {alert('Wrong!!!!!!!')
                };
              */  
            } 
            }, 450); 
            
            self.disabled(true); 
/*setTimeout(function(){             
      puzzleEditorViewModel.displayMessage(false);
      solved(window.puzzle); 
      self.disabled(true); 
      }, 470);
  */
  } 

  self.goblin = function(){
    var pM = [];
    var pM1 = [];
    var m1,m2,m3;
    var puzzleMoves = self.chess.moves({verbose: true}).filter(move => move.flags === 'n' && move.piece === 'y');
    while (puzzleMoves.length > 0) {
      pM = [];
      m1 = puzzleMoves[0].to;
      pM = puzzleMoves.filter(m => m.to === m1);
      pM1.push(pM[Math.floor(Math.random()*pM.length)]);
      puzzleMoves = puzzleMoves.filter(m => m.to !== m1);
    };   
    puzzleMoves = [];   
    puzzleMoves = pM1;  
    if (puzzleMoves.length > 0){  
      setTimeout(function(){
        while (puzzleMoves.length > 0) {
          m1 = puzzleMoves[0].from;
          pM = puzzleMoves.filter(m => m.from === m1);
          m2 = pM[Math.floor(Math.random()*pM.length)];
          puzzleMoves = puzzleMoves.filter(m => m.from !== m1);
          m3 = puzzleMoves.length;
          if (m3>0) {self.pturn(true);self.chess.turn('b');self.turn('b');} else {self.pturn(false);self.chess.turn('w');self.turn('w');};
          self.drop3(m2,m3);
          self.makeMove23({from: m2.from, to: m2.to}, true, m3);
        };
      }, 390);
    }else {    
      self.pturn(false);
      self.turn('w');
      self.chess.turn('w');   
     }      
  };
  
  self.drop3 = function(m2,m3){
  
  };
  
  self.drop = function(piece, pos){
    var moves, m5;
    self.activePiece(null);
    var moveMode = self.moveMode();
    if(moveMode === 'free'){
      self.reset(piece.pos(), {type: piece.type(), color: piece.color()}, pos)
    }
    else if(moveMode === 'rules') {
      // do move
      var rank = Utils.rank(pos);
      if(piece.type() === 'p' && (rank === 7 || rank === 0)) {
        // promotion
        self.promotingMove = {from: piece.pos(), to: pos, piece: piece}
        $("#promotionModal").modal();
      } else {
        var moveObject = self.makeMove({from: piece.pos(), to: pos}, true);
        if (window.puzzle && window.puzzle.category === 0) {
          if (self.chess.game_over()) {
            window.setTimeout(alert('Game over'), 250); 
            return;
          }
          $('#board').addClass('bot'); 
          $('#board').removeClass('my');
          window.setTimeout(window.makeComputerMove, 1250); 
        } 
        if (window.puzzle && window.puzzle.category != 0){
          self.goblin3();
        }
      }
    }
    //   console.log(' ---0--- ' + JSON.stringify(moveObject));
    if (window.puzzle) {
      if (window.puzzle.category === 5) {
      	m5 = self.chess.moves({verbose: true}).filter(move => move.flags === 'c') 
        //   	alert(JSON.stringify(m5));
        if (m5.length > 0) {
          self.pcturn(true);
          self.turn('w');
          self.chess.turn('b');
          self.goblin2(m5[0]);
        }else {
          self.pcturn(false);
          self.turn('w');
          self.chess.turn('w');
        }
      }
    
      if (window.puzzle.category === 4) {
        m5 = self.chess.moves({verbose: true}).filter(move => move.flags === 'c') 
        if ((m5.length === 0 || moveObject.flags.indexOf('c') === -1) && self.pieces().filter(piece => piece.color() === 'b').length > 0) {	
          self.goblin4();
        }   
      }
      
      if (window.puzzle.category > 1 && self.pieces().filter(piece => piece.color() === 'b').length < 1) {
        puzzleEditorViewModel.displayMessage(false);
        solved(window.puzzle); 
        self.disabled(true); 
      }
      if (window.puzzle.category === 0) {
        //window.setTimeout(window.makeComputerMove, 500); 
      }  
    }
  }

  self.goblin3 = function () {
     if (window.puzzle && window.puzzle.category === 3 && self.pieces().filter(piece => piece.type() === 'y').length > 0) {
      //console.log(' ---000--- ' + JSON.stringify(self.chess.moves())); 
      if (self.chess.moves({verbose: true}).filter(move => move.flags === 'n' && move.piece === 'y').length > 0) { 
       
        setTimeout(function() {
          //console.log(' ---1--- ' + JSON.stringify(moveObject));   
          self.pmturn(false);
        }, 100); 
        setTimeout(function() {
          //console.log(' ---2--- ' + self.pmturn() + JSON.stringify(moveObject));   
          self.goblin();
        }, 150); 
      }else {
         self.pmturn(true);
         self.pturn(false);
         self.turn('w');
         self.chess.turn('w');
      }

    }    
  }

  self.undoMove = function() {
    var move = self.currentPgnMove(), prevMove;
    if(move) {
      prevMove = move.prevMove();
      if(prevMove) {
        self.show(prevMove.fen);
      } else {
        self.show(self.pgn().initialFen);
      }
      self.currentPgnMove(prevMove);
      move.parent.removeNode(move);
      self.pgn.valueHasMutated();
    }
  }

  self.promotion = function(piece_type) {
    if(self.promotingMove) {
      var moveObject = self.promotingMove
      moveObject.promotion = piece_type
      moveObject = self.makeMove(moveObject, true)
    }
    $("#promotionModal").modal('hide');
    self.promotingMove = null;
    //console.log(' ---1111--- ' + JSON.stringify(moveObject));
    if (window.puzzle && window.puzzle.category > 1 && self.pieces().filter(piece => piece.color() === 'b').length < 1) {
      puzzleEditorViewModel.displayMessage(false);
      solved(window.puzzle); 
      self.disabled(true); 
    }    
    if (window.puzzle && window.puzzle.category === 0) {
      if (self.chess.game_over()) {
        window.setTimeout(alert('Game over'), 250); 
        return;
      }
      $('#board').addClass('bot'); 
      $('#board').removeClass('my');
      window.setTimeout(window.makeComputerMove, 1250); 
    } 
    self.goblin3();
  }

  self.moving = ko.observable(false);

  var _realMakeMove = function(moveObject) {
    self.moving(true)
    self.turn(self.chess.turn()) // update current turn observable

    if(moveObject.capture) {
      //remove captured piece
      self.pieces.remove(self.findPieceByPos(moveObject.capture))
    }
    if(moveObject.flags.indexOf('k') !== -1 || moveObject.flags.indexOf('q') !== -1) {
      var rook = self.findPieceByPos(moveObject.castling_from);
      if(rook && rook.type() === 'r') {
        rook.pos(moveObject.castling_to);
      }
    }
    piece = self.findPieceByPos(moveObject.from);
    if(piece) {
      piece.pos(moveObject.to);
      if(moveObject.flags.indexOf('p') !== -1) {
        // promote
        piece.type(moveObject.promotion)
      }
    }
    self.recordPgn(moveObject)
    self.moving(false)
  }
  
  self.makeMove = function(moveObject, userInteraction) {
    var branchMethod = moveObject.branchMethod || self.options.branchMethod
    self.movelast.removeAll(); 
    var moveObject = self.chess.move(moveObject);
    var piece, nm;
    if(moveObject){
      if(!window.puzzle || (window.puzzle && window.puzzle.category === 1) || (window.puzzle && window.puzzle.category === 0)){
        self.movelast.push(moveObject.from);
        self.movelast.push(moveObject.to);
        var nextMoves = self.pgn().nextMovesFrom(self.currentPgnMove());
        for (var i=0;i<nextMoves.length;i++){
          if(moveObject.san === nextMoves[i].san){
            if (sound) {document.getElementById('m001').play(); } 
            self.goToMoveNode(nextMoves[i])
            if(window.puzzle  && window.puzzle.category != 0 && resourceUserA === 'index'){
              if(self.flip()){
                puzzleEditorViewModel.solution(puzzleEditorViewModel.solution()+self.pgn().body.moves[self.currentPgnMove().number-1]+' ');
              }else{
                puzzleEditorViewModel.solution(puzzleEditorViewModel.solution()+self.pgn().body.moves[self.currentPgnMove().number]+' ');
              }
              if(self.currentPgnMove().number >= self.pgn().body.moves.length-1){
                if (resourceUserB == "puzzle" || resourceUserB == "chapter") {
                  puzzleEditorViewModel.displayPgn(true);
                  puzzleEditorViewModel.displayMessage(false);
                  window.keyboardController.bind("PgnNavHandler", puzzleEditorViewModel.board);
                }
                solved(window.puzzle);
              }else{
                if(self.flip()){
                  setTimeout(function(){
                    self.goToMoveNode(self.pgn().body.moves[self.currentPgnMove().number]);
                    if (sound) { document.getElementById('m001').play();  }                      
                    self.movelast.removeAll();
                    self.movelast.push(self.currentPgnMove().from);
                    self.movelast.push(self.currentPgnMove().to);
                  }, 700);
                  puzzleEditorViewModel.solution(puzzleEditorViewModel.solution()+self.pgn().body.moves[self.currentPgnMove().number]);                                       
                }else{
                  setTimeout(function(){
                    self.goToMoveNode(self.pgn().body.moves[self.currentPgnMove().number+1]);
                    if (sound) {document.getElementById('m001').play();   }                       
                    self.movelast.removeAll(); 
                    self.movelast.push(self.currentPgnMove().from);
                    self.movelast.push(self.currentPgnMove().to);
                  }, 700);
                  puzzleEditorViewModel.solution(puzzleEditorViewModel.solution()+self.pgn().body.moves[self.currentPgnMove().number+1]+' ');                                      
                }
                if(self.pgn().body.moves.length - self.currentPgnMove().number < 3 || (window.puzzle.solved || window.puzzle.err || puzzleEditorViewModel.board.pgn().body.moves.length <3 || 
                  (window.puzzle.hint + window.puzzle.mistake)>= Math.round((puzzleEditorViewModel.board.pgn().body.moves.length-1)/5) || 
                  window.puzzle.userRating < 2300 || (window.puzzle.hint + window.puzzle.mistake) > 4)){
                  puzzleEditorViewModel.hintButton(true);
                }else{
                  puzzleEditorViewModel.hintButton(false);
                }                                        
              }
            }
            return;
          }
        }
      }else if (window.puzzle && window.puzzle.category === 4) {
          
      }

      if(window.puzzle && resourceUserA === 'index' && window.puzzle.category === 1){
        self.movelast.removeAll();
        self.showCurrentMoveNode();
        if(!window.puzzle.solved && !window.puzzle.err){
          window.puzzle.mistake +=1;
          if(window.puzzle.solved || window.puzzle.err || 
            (window.puzzle.hint + window.puzzle.mistake)>= Math.round((puzzleEditorViewModel.board.pgn().body.moves.length-1)/5) || 
            (window.puzzle.hint + window.puzzle.mistake) > 4){
            puzzleEditorViewModel.hintButton(true);
          };
          if(puzzleEditorViewModel.board.pgn().body.moves.length < 3 || (window.puzzle.hint + window.puzzle.mistake) > 4 || 
            (window.puzzle.hint + window.puzzle.mistake) > Math.round((puzzleEditorViewModel.board.pgn().body.moves.length-1)/5)){
            wsolve(window.puzzle);
          }else{
            mistake();
          }
        }else{ 

          $("#taskModal1").modal('show');
          if (sound) {document.getElementById("m010").play(); }         
          setTimeout(function() {
            $('#taskModal1 .close-btn').click();
          }, 2000);           
                             
        }

      }else{
        
        if (sound) { document.getElementById('m001').play();}  
        var _continueMove = function(branchMethod) {
          if(branchMethod) {
            moveObject.branchMethod = branchMethod;
          }
          _realMakeMove(moveObject);
          if(moveObject && self.onmove && userInteraction){
            self.onmove(moveObject);
          }
        }
        if( self.currentPgnMove() ? self.currentPgnMove().nextMove() : self.pgn().body.moves[0]){
          if(!branchMethod || (!userInteraction && branchMethod === 'ask')){
            branchMethod = 'new'
          }
          if(branchMethod === 'ask'){
            self.branchMethodCallback(_continueMove);
          }else{
          _continueMove(branchMethod)
          }
        }else{
        _continueMove(null);
        }
        return moveObject;
      }
    }
else {
      console.log("Invalid move");
      self.updateBoard();
      return null;
    }
  }


  self.makeMove23 = function(moveObject, userInteraction, m3){
    var branchMethod = moveObject.branchMethod || self.options.branchMethod
    self.movelast.removeAll(); 
    var moveObject = self.chess.move(moveObject, m3);
    var piece, nm;
    if(moveObject){
//&&&&&&???????????       
if (sound) { document.getElementById('m002').play(); }
           var _continueMove = function(branchMethod) {
             if(branchMethod) {
               moveObject.branchMethod = branchMethod;
               }
             _realMakeMove(moveObject);
             if(moveObject && self.onmove && userInteraction){
               self.onmove(moveObject);
               }
           }
      if( self.currentPgnMove() ? self.currentPgnMove().nextMove() : self.pgn().body.moves[0]){
        if(!branchMethod || (!userInteraction && branchMethod === 'ask')){
          branchMethod = 'new'
        }
        if(branchMethod === 'ask'){
          self.branchMethodCallback(_continueMove);
          }else{
           _continueMove(branchMethod)
           }
        }else{
         _continueMove(null);
         }
      return moveObject;
     
    }
    else {
      console.log("Invalid move");
      self.updateBoard();
      return null;
    }
  }
  
  
//------------------------------------------------------
  self.cellpaling = function () {
       for(var i=0;i<self.pieces().length;i++) {
         if (self.pieces()[i].type() === 'u') {
             self.palings.push(self.pieces()[i].pos());
         }
       }
  }
//------------------------------------------------------  
  self.branchMethodCallback = ko.observable(null);
  self.cancelBranchMethodQuestion = function() {
      self.movelast.removeAll();
    self.showCurrentMoveNode()
  }

  self.answerBranchMethod = function(branchMethod) {
    var cb = self.branchMethodCallback();
    if(cb) {
      cb(branchMethod);
      self.branchMethodCallback(null);
    }
  }

  self.recordPgn  = function(moveObject) {
    var move = self.currentPgnMove();
    move = self.pgn().insertMove(moveObject.san, move, moveObject.branchMethod);
    self.currentPgnMove(move);
    self.pgn.valueHasMutated();
  }

  self.findPieceByPos = function(pos) {
    var i;
    for(i=0;i<self.pieces().length;i++) {
      var piece = self.pieces()[i];
      if(piece.pos() === pos) {
        return piece;
      }
    }
    return null;
  }

  self.initCells = function(){
    var i,j;
    for(i=0;i<8;i++) {
      for(j=0;j<8;j++) {
        var san = Utils.algebraic(i,j);
        self.cells.push(new Cell(self, san));
      }
    }
  }

  self.updateBoard = function() {
    self.pieces.removeAll();
//    self.movelast.removeAll();
    var i,j, piece;
    for(i=0;i<8;i++) {
      for(j=0;j<8;j++) {
        var san = Utils.algebraic(i,j);
        piece = this.chess.get(san);
        if(piece) {
          self.pieces.push(new Piece(self, piece.color, piece.type, san));
        }
      }
    }
    self.turn(self.chess.turn())
    if(window.puzzle && (window.puzzle.category === 3 || window.puzzle.category === 2)){

    }
//    self.fogmove();//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    self.cellpaling();
    $(".b-u").removeClass("ui-draggable");
    $(".w-u").removeClass("ui-draggable")
    $(".b-u").addClass("ui-draggable-disabled");
    $(".w-u").addClass("ui-draggable-disabled");
 //   $(".w-u").droppable("destroy");
 //   $(".b-u").droppable("destroy");

  }

  self.startDrag = function(){
    if(self.canThrowOut()) {
      $(document.body).droppable('option', 'accept', '.piece')
    }
  }

  self.cellClicked = function (pos) {
    var piece = self.selectedAddingPiece()
    if (piece && self.moveMode() === 'free') {
      self.reset(null, {type: piece.type, color: piece.color}, pos)
    }
  }

  self.fen = function() {
    return self.chess.fen();
  }

  self.load = function(fen) {
    self.activePiece(null);
    self.show(fen);
    self.currentPgnMove(null);
    self.pgn(new PGN.Game({header: {FEN: fen}}))
  }

  self.show = function(fen) {
    self.chess.load(fen);
    self.updateBoard();
  }


  self.pgn = ko.observable(new PGN.Game());
  self.currentPgnMove = ko.observable();

  self.loadPgn = function(pgnText) {
    var pgnParser = new pgn.Parser(), parsedPgn;
    try {
      parsedPgn = pgnParser.parse(pgnText);
    } catch (error) {
      alert(error.message);
    }
    if(parsedPgn) {
      var pgnGame;
        pgnGame = new PGN.Game(parsedPgn[0])
      self.pgn(pgnGame);
      self.goToMoveNode(null)
    }
  }

  self.goToMove = function(moveIdent) {
    var node;
    if(moveIdent) {
      node = self.pgn().findByIdent(moveIdent)
    }
    
    self.goToMoveNode(node);
  }

  self.goToMoveNode = function(moveNode) {
    self.currentPgnMove(moveNode);
    self.showCurrentMoveNode();
  }

  self.showCurrentMoveNode = function (){
    self.showNextMoves(false);
    self.branchMethodCallback(null);
    self.showMoveNode(self.currentPgnMove());
  }

  self.showMoveNode = function(moveNode) {
    if(moveNode) {
      self.show(moveNode.fen);
    } else {
      self.show(self.pgn().initialFen);
    }
  }

  self.showNextMoves = ko.observable(false);

  self.nextMoves = ko.computed(function(){
    return self.pgn().nextMovesFrom(self.currentPgnMove());
  });

  self.selectedNextMoveIndex = ko.observable(0)

  self.backEnabled = ko.computed(function(){
    var move = self.currentPgnMove();
    return !!move;
  });

  self.forwardEnabled = ko.computed(function(){
    var move = self.currentPgnMove();
    var nextMove;
    if(move) {
      nextMove = move.nextMove();
    }
    else {
      if(self.pgn()) {
        nextMove = self.pgn().body.moves[0];
      }
    }
    return !!nextMove;
  });

  self.goBack = function(){
    var move, prevMove;
    move = self.currentPgnMove();
    if(move) {
        self.movelast.removeAll(); 
      prevMove = move.prevMove();
      self.goToMoveNode(prevMove);
    }
    return prevMove;
  }

  self.goForward = function() {
    var move, nextMove;
    move = self.currentPgnMove();
    if(move) {
      nextMove = move.nextMove();
    } else {
      nextMove = self.pgn().body.moves[0];
    }

    if(nextMove) {
      self.goToMoveNode(nextMove);
    }

    return nextMove;
  }

  self.goToBegin = function() {
    self.goToMoveNode(null);
    return null;
  }

  self.goToEnd = function() {
    var move = self.currentPgnMove(), nodeList, lastMove;
    if(move) {
      nodeList = move.parent;
    }
    else {
      if(self.pgn()) {
        nodeList = self.pgn().body;  
      }
    }

    if(nodeList && nodeList.moves && nodeList.moves.length > 0) {
      lastMove = nodeList.moves[nodeList.moves.length - 1];
    }

    self.goToMoveNode(lastMove);

    return lastMove;
  }

  self.inCheckmate = function() {
    return self.chess.in_checkmate();
  }
  self.inCheck = function() {
    return self.chess.in_check();
  }

  self.inStalemate = function() {
    return self.chess.in_stalemate();
  }

  self.inThreefoldRepetition = function() {
    return self.chess.in_threefold_repetition();
  }

  self.inFiftyMoveDraw = function() {
    return self.chess.in_fifty_move_draw();
  }

  self.insufficientMaterial = function() {
    return self.chess.insufficient_material();
  }

  self.inDraw = function(){
    return (self.rules.fiftyMove && self.chess.in_fifty_move_draw()) ||
      self.chess.in_stalemate() ||
      (self.rules.insufficientMaterial && self.chess.insufficient_material()) ||
      (self.rules.threefoldRepetition && self.chess.in_threefold_repetition())
  }

  self.initCells();
  self.updateBoard();
};
window.ChessBoard = ChessBoard;
}).call(this);
